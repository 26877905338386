<template>
<b-row align-h="center" align-v="center" class="my-1">
      <b-col cols="12" class="d-block d-md-none">
            <hr />
      </b-col>
       <b-col md="2" class="d-none d-md-block">
            {{game.group.name}}
      </b-col>
      <b-col md="2" class="text-left d-none d-md-block">
            {{date}}
      </b-col>
      <b-col cols="6" class="d-block d-md-none">
            {{date}}
      </b-col>
      <b-col cols="6" class="text-right d-block d-md-none">
            {{game.group.name}}
      </b-col>
      <b-col v-if="hteam" @click="GoTo(hteam)" class="groups-team" cols="5" md="3" v-bind:class="{ winner: winner == 1 }">
           <Team :team="hteam"></Team>
      </b-col>
      <b-col v-else class="groups-emtpy-team" cols="5" md="3">
            TBD
      </b-col>
      <b-col cols="2">
            <b-row no-gutters class="text-center">
                  <b-col cols="4" v-bind:class="{ winner: winner == 1 }">
                        {{game.homescore}}
                  </b-col>
                  <b-col cols="4">-</b-col>
                  <b-col cols="4" v-bind:class="{ winner: winner == 2 }">
                        {{game.awayscore}}
                  </b-col>
            </b-row>
      </b-col>
      <b-col v-if="ateam" @click="GoTo(ateam)" class="text-right groups-team" cols="5" md="3" v-bind:class="{ winner: winner == 2 }">
           <Team :team="ateam" away></Team>
      </b-col>
      <b-col v-else class="text-right groups-emtpy-team py-3" cols="5" md="3">
            TBD
      </b-col>
</b-row>
</template>

<script>

const Team = () => import("@/components/team/Team");

export default {
      name: "TeamGameRow",
      props: ["game"],
      components: {
            Team
      },
      data() {
            return {};
      },
      computed: {
            hteam() {
                  return this.$functions.getTeam(this.game.hometeamid);
            },
            ateam() {
                  return this.$functions.getTeam(this.game.awayteamid);
            },
            winner() {
                  if (this.game.homescore > this.game.awayscore)
                        return 1;
                  else if (this.game.homescore < this.game.awayscore)
                        return 2;
                  return 0;
            },
            date() {
                  return this.$date.TeamGroupDate(this.game.gamedate);
            }
      },
      methods: {
            flag(team) {
                  return this.$images.teamFlag(team.flag);
            },
            GoTo(team) {
                  var url = this.$url.teamurl(team);
                  this.$router.push(url);
            },
      },
      mounted() {},
};
</script>
